import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Header = () => (
  <StaticQuery
    query={headerQuery}
    render={data => {
      const {
        header: { frontmatter: { header } },
        subheader:
        {
          frontmatter: {
            buttonText
          },
          html: subheader
        },
        images: {
          frontmatter: {
            imageDesktop,
            imageTablet,
            imageMobileLarge,
            imageMobileMiddle,
            imageMobileSmall
          }
        }
      } = data
      const sources = [
        imageDesktop.childImageSharp.fluid,
        {
          ...imageMobileSmall.childImageSharp.fluid,
          media: `(max-width: 375px)`,
        },
        {
          ...imageMobileMiddle.childImageSharp.fluid,
          media: `(max-width: 420px)`,
        },
        {
          ...imageMobileLarge.childImageSharp.fluid,
          media: `(max-width: 699px)`,
        },
        {
          ...imageTablet.childImageSharp.fluid,
          media: `(max-width: 960px)`,
        }
      ]
      return (
        <header className="header">
          <div className="header-context-wrapper">
            <div className="header-context">
              <h1 className="header-text">{header}</h1>
              <div className="subheader-text">
                <span className="line"></span>
                <div
                  dangerouslySetInnerHTML={{ __html: subheader }}
                />
              </div>
              <Link
                to="/contact-us/"
                className="button-link">
                {buttonText}
              </Link>
            </div>
          </div>
          <div className="header-image-wrapper">
            <Img
              fluid={sources}
              objectFit="contain"
              loading="eager"
              alt="main picture"
            />
          </div>
        </header>
      )
    }}
  />
)

export default Header

export const headerQuery = graphql`
  query {
    header: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/header.md/"}
    ) {
      frontmatter {
        header
      }
    }
    subheader: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/subheader.md/"}
    ) {
      frontmatter {
        buttonText
      }
      html
    }
    images: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/main-picture.*/"}
    ) {
      frontmatter {
        imageDesktop: desktop {
          childImageSharp {
            fluid (
              maxWidth: 952,
              maxHeight: 785,
              quality: 85,
              base64Width: 952,
              srcSetBreakpoints: [952]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageTablet: tablet {
          childImageSharp {
            fluid (
              maxWidth: 456,
              maxHeight: 488,
              quality: 85,
              base64Width: 458,
              srcSetBreakpoints: [456]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobileLarge: mobile {
          childImageSharp {
            fluid (
              maxWidth: 699,
              maxHeight: 680,
              quality: 85,
              cropFocus: NORTH,
              srcSetBreakpoints: [699]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobileMiddle: mobile {
          childImageSharp {
            fluid (
              maxWidth: 699,
              maxHeight: 846,
              quality: 85,
              cropFocus: SOUTH,
              srcSetBreakpoints: [699]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobileSmall: mobile {
          childImageSharp {
            fluid (
              maxWidth: 699,
              maxHeight: 888,
              quality: 85,
              srcSetBreakpoints: [699]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  } 
`
