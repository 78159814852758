import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import RedirectToGeneral from './UI/RedirectToGeneral/RedirectToGeneral'
import { updateImage } from '../utils/updateImage'

/**
 * Component represents data about posts
 */

const Blog = () => (
  <StaticQuery
    query={articlesQuery}
    render={data => {
      const {
        articles:
          { edges: articlesArray },
        selectedArticles: {
          frontmatter: {
            sectionName,
            linksText,
            articles: selectedArticlesObj
          }
        }
      } = data

      const listOfTitle = Object.values(selectedArticlesObj)
      const filteredArticles = articlesArray
        .filter(({ node }) => listOfTitle
          .some(title => title === node.frontmatter.title))

      return (
        <section className="blog wrapper">
          <div className="section-header">
            <div className="section-name">
              <div className="line"/>
              <Link to="/blog/" className="name">{sectionName}</Link>
            </div>
            <RedirectToGeneral
              page='blog'
              textDeskTop={linksText.desktop}
              textMobile={linksText.mobile}
            />
          </div>
          <div className="blog-wrapper">
            {filteredArticles.map(({ node }) => (
              <a
                href={`/blog/${ node.fields.slug }/`}
                className="article-link"
                key={node.id}>
                <Img
                  className="article-image"
                  fluid={{
                    // removed largest image size from srcSet, if it exist
                    ...updateImage(node.frontmatter.homePageImage
                      ? node.frontmatter.homePageImage.childImageSharp.fluid
                      : node.frontmatter.image.childImageSharp.fluid),
                    sizes: `(max-width: 380px) 320px,
                      (max-width: 440px) 380px,
                      (max-width: 992px) 454px, 614px`
                  }}
                  loading="eager"
                />
                <div className="article-content">
                  <div className="row">
                    <p className="article-header">{ node.frontmatter.title }</p>
                    <div className="author-date-block">
                      <div className="author-image">
                        <Img fluid={node.frontmatter.authorImage.childImageSharp.fluid}/>
                      </div>
                      <div className="author-date-wrapper">
                        <p className="article-author">{ node.frontmatter.author }</p>
                        <p>{ node.frontmatter.date }</p>
                      </div>
                    </div>
                  </div>
                  <p className="article-description">{ node.excerpt }</p>
                </div>
              </a>
            ))}
          </div>
        </section>
      )
    }}
  />
)

export default Blog

export const articlesQuery = graphql`
  query {
    articles: allMarkdownRemark (
      limit: 1000
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {fileAbsolutePath: {regex: "/content/individual-pages/articles/"}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            author
            authorImage {
              childImageSharp {
                fluid(
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image {
              childImageSharp {
                fluid(
                  maxWidth: 614,  
                  quality: 85,
                  cropFocus: CENTER,
                  srcSetBreakpoints: [320, 380, 454, 614]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            homePageImage {
              childImageSharp {
                fluid(
                  maxWidth: 614,  
                  quality: 85,
                  cropFocus: CENTER,
                  srcSetBreakpoints: [344, 352, 454, 614]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt(pruneLength: 600)
          fields {
            slug
          }
        }
      }
    }
    selectedArticles: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/home/home-page-articles.md/"}
    ) {
      frontmatter {
        sectionName
        articles {
          first
          second
          third
          fourth
        }
        linksText {
          desktop
          mobile
        }
      }
    }
  }
`
