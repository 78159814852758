import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'

import Service from './Service/Service'
import RedirectToGeneral from './UI/RedirectToGeneral/RedirectToGeneral'
import { slugify } from '../utils/utilityFunctions'

/**
 * Component represents data about services
 */

const Services = () => (
  <StaticQuery query={serviceQuery} render={data => {
    const { sectionName, services, linksText } = data.markdownRemark.frontmatter
    return (
      <section className="services" id="services">
        <div className="services-wrapper wrapper">
          <div className="section-header">
            <div className="section-name">
              <div className="line"/>
              <Link to="/services/" className="name">{sectionName}</Link>
            </div>
            <RedirectToGeneral
              page='services'
              textDeskTop={linksText.desktop}
              textMobile={linksText.mobile}
            />
          </div>
          <div className="services-item-wrapper">
            {services.map((service, index) => (
              <Service
                key={index}
                image={service.image.childImageSharp.fluid}
                order={index + 1}
                header={service.title}
                description={service.description}
                redirectionButtonName={service.referenced.buttonText}
                slug={
                  `/services/${
                    slugify(service.referenced.redirectLink)
                  }/`
                }
              />
            ))}
          </div>
        </div>
      </section>
    )
  }} />
)

export default Services

export const serviceQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/services.*/"}
    ) {
      frontmatter {
        sectionName
        services {
          title
          description
          referenced {
            redirectLink
            buttonText
          }
          image {
            childImageSharp {
              fluid (
                maxWidth: 504,
                maxHeight: 355,
                quality: 85,
                srcSetBreakpoints: [320, 380, 504]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        linksText {
          desktop
          mobile
        }
      }
    }
  }
`
