import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import { slugify } from '../utils/utilityFunctions'

/**
 * Component represents data about technologies
 */

const Technologies = () => (
  <StaticQuery
    query={technologiesQuery}
    render={data => {
      const {
        sectionName,
        technologies,
      } = data.markdownRemark.frontmatter

      return (
        <section className="technologies wrapper">
          <div className="section-header">
            <div className="section-name">
              <div className="line"/>
              <Link to="/technologies/" className="name">{sectionName}</Link>
            </div>
          </div>
          <div className="technologies-wrapper">
            {technologies.slice(0, 6).map((technology, index) => {
              const relation = !!(technology.referenced &&
                technology.referenced.redirectLink)
              return (
                <div className={`technology-item-${ index } technology`} key={index}>
                  <div className="top-section">
                    <a
                      className="technology-image"
                      {
                      ...(relation && { href: `/technologies/${
                        slugify(technology.referenced.redirectLink)
                      }/` })}
                    >
                      <img
                        src={require(`../assets/technology-${ technology.imageName }.svg`)}
                        alt={technology.title} />
                    </a>
                    <a
                      {
                      ...(relation && { href: `/technologies/${
                        slugify(technology.referenced.redirectLink)
                      }/` })}
                    >
                      { technology.title }
                    </a>
                  </div>
                  <p
                    className="technology-description"
                    dangerouslySetInnerHTML={{ __html: technology.description }}
                  />
                  {relation && technology.referenced.buttonText && (
                    <Link
                      to={`/technologies/${
                        slugify(technology.referenced.redirectLink)
                      }/`}
                      className="technology-link"
                    >
                      {technology.referencredirectLink}
                    </Link>
                  )}
                </div>
              )
            })}
          </div>
        </section>
      )
    }}
  />
)

export default Technologies

export const technologiesQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/technologies.*/"}
    ) {
      frontmatter {
        sectionName
        technologies {
          title
          description
          imageName
          referenced {
            redirectLink
            buttonText
          }          
        }
      }
    }
  }
`
