import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/Seo'
import Header from '../components/Header'
import Projects from '../components/Projects'
import Technologies from '../components/Technologies'
import Blog from '../components/Blog'
import Services from '../components/Services'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(
          fileAbsolutePath: {regex: "/content/general-pages/home/seo-home.md/"}
        ) {
          frontmatter {
            seo {
              canonical
              title
              description
              keywords
              robots
              image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              imageAlt
              twitterCard
            }
          }
        }
      }
    `)
  const {
    frontmatter: {
      seo: seoInfo
    }
  } = data.markdownRemark
  return (
    <Fragment>
      <SEO data={seoInfo} />
      <Header />
      <Services />
      <Projects />
      <Technologies />
      <Blog />
    </Fragment>
  )
}

export default IndexPage
