import React from 'react'

import classes from './RedirectToGeneral.module.scss'
import Icon from '../Icon/Icon'

const RedirectToGeneral = ({ page, textMobile, textDeskTop, colorWhite }) => {
  const cls = [classes.RedirectGeneral]
  if (colorWhite) {
    cls.push(classes.White)
  }

  return (
    <a
      className={cls.join(' ')}
      href={`/${ page }/`}
    >
      <span className={classes.TextDesktop}>
        {textDeskTop}
      </span>
      <span className={classes.TextMobile}>
        {textMobile}
      </span>
      <div className={classes.RightArrow}>
        <Icon type='right-arrow'/>
      </div>
    </a>
  )
}

export default RedirectToGeneral
