import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

// import RedirectToGeneral from './UI/RedirectToGeneral/RedirectToGeneral'
import { slugify } from '../utils/utilityFunctions'
import { updateImage } from '../utils/updateImage'

/**
 * Component represents data about projects
 */

const Projects = () => (
  <StaticQuery
    query={projectsQuery}
    render={data => {
      const { sectionName, projects, linksText } = data.markdownRemark.frontmatter
      return (
        <section className="projects" id="projects">
          <div className="projects-wrapper wrapper">
            <div className="section-header">
              <div className="section-name white">
                <div className="line"/>
                <Link
                  to="/projects/"
                  className="name"
                  style={{ pointerEvents: 'none' }}>{sectionName}</Link>
              </div>
              {/* <RedirectToGeneral
                page='projects'
                textDeskTop={linksText.desktop}
                textMobile={linksText.mobile}
                colorWhite={true}
              /> */}
            </div>
            {projects.map((project, index) => {
              const projectLink = `/projects/${
                slugify(project.referenced.redirectLink)
              }/`
              // removed largest image size from srcSet, if it exist
              const updatedImage = updateImage(project.image.childImageSharp.fluid)

              return (
                <div className="project" key={index}>
                  <a
                    className="project-header-mobile"
                    href={projectLink}
                  >
                    { project.title }
                  </a>
                  <div className="project-wrapper">
                    <ul className="tags">
                      {project.tags.slice(0, 3).map((tag, index) => (
                        <li key={index}>{tag}</li>
                      ))}
                    </ul>
                    <a
                      className="project-header"
                      href={projectLink}
                    >
                      { project.title }
                    </a>
                    <div
                      className="project-description"
                      dangerouslySetInnerHTML={{ __html: project.description }} />
                    <Link
                      to={projectLink}
                      className="project-link"
                    >
                      {project.referenced.buttonText}
                    </Link>
                  </div>
                  <a
                    className="project-image-link"
                    href={projectLink}
                  >
                    <Img
                      className="project-image"
                      fluid={{
                        ...updatedImage,
                        sizes: `(max-width: 440px) 360px, 736px`
                      }}
                      loading="eager"
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </section>
      )
    }}
  />
)

export default Projects

export const projectsQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/home/projects.*/"}
    ) {
      frontmatter {
        sectionName
        projects {
          title
          description
          referenced {
            redirectLink
            buttonText
          }
          tags
          image {
            childImageSharp {
              fluid(
                maxWidth: 736, 
                quality: 85
                srcSetBreakpoints: [360, 736]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        linksText {
          desktop
          mobile
        }
      }
    }
  }
`
